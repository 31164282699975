import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { TileLayerContext } from './TileLayerContext';
import { TileWMS } from 'ol/source';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

import OSM from "ol/source/OSM";
import { getRenderPixel } from "ol/render";
import { useAppSelector } from '../../../../../state/hooks';
import { RootState } from '../../../../../state/store';
import { Project } from '../../../../../util/model/project';

interface Props {
    url: string;
    visible: boolean;
    layername?: string;
    opacity?: number;
}
export const Tile = (props: PropsWithChildren<Props>) => {
    const map = useContext(MapContext)
    const t = useRef<TileLayer<any>>(new TileLayer())

    const swipe :any= document.getElementById("swipe");
    const swipeVisibility: any = useAppSelector(state => state.buttonState.swipeButton);
    const services: any = useAppSelector(state => state.layer.services);

    const [swipes, setSwipes] = useState<any>([]);

    const projectsSwipe: Project[] = useAppSelector((state: RootState) => state.layer.projectsSwipe);
    const sortNullishValues = (arr = []) => {
        const assignValue = (val :any) => {
           if (val === null) {
              return Infinity;
           } else {
              return val;
           };
        };
        // sort((a:any, b:any) => (a.priority ? a.priority : 1 > b.priority ? b.priority : 1) ? 1 : -1).
        const sorter = (a:any, b:any) => {
           return assignValue(a.priority ? a.priority : 1) - assignValue(b.priority ? b.priority : 1);
        };
       return [...arr].sort(sorter);
     }

    useEffect(() => {
        if(swipeVisibility){
            projectsSwipe.map((project: any) => {
              project.layer_groups.map((layerGroup: any) => {
                const layers_: any = [];
                const layerNames_: any = [];
                sortNullishValues(layerGroup.layers).map((layer: any) => {
                    if (layer.visible) {
                    layers_.push(layer);
                    layerNames_.push(layer.name);
                    }
                });
                if (layers_.length) {
                        const aeria2 = new ImageLayer({
                            source: new ImageWMS({
                                url: `${services.find((service: any) => service.id === layers_[0].service_id)?.url}` + '/wms',
                                params: { 
                                    layers: layerNames_
                                },
                                serverType: "geoserver",
                                crossOrigin: "anonymous",
                            }),
                            zIndex:999999999999999999999999,
                            properties: {
                                alias: "swipe"
                            }
                        });
                        
                        aeria2.on("prerender", function (event) {
                            const ctx :any= event.context;
                            const mapSize:any = map.getSize();
                            const width = mapSize[0] * (swipe.value / 100);

                            ctx.restore();
                            // Draw splitter line
                            ctx.beginPath();
                            ctx.strokeStyle = '#000000';
                            ctx.lineWidth = 2.3;
                            ctx.moveTo(width, 0);
                            ctx.lineTo(width, mapSize[1]);
                            ctx.stroke();

                            const tl = getRenderPixel(event, [width, 0]);
                            const tr = getRenderPixel(event, [mapSize[0], 0]);
                            const bl = getRenderPixel(event, [width, mapSize[1]]);
                            const br = getRenderPixel(event, mapSize);

                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(tl[0], tl[1]);
                            ctx.lineTo(bl[0], bl[1]);
                            ctx.lineTo(br[0], br[1]);
                            ctx.lineTo(tr[0], tr[1]);
                            ctx.closePath();
                            ctx.clip();
                        });

                        aeria2.on("postrender", function (event) {
                            const ctx:any = event.context;
                            ctx.restore();
                        });
                     
                        map.addLayer(aeria2);

                        // setSwipes([...swipes, aeria2])
                }
        
                });
            })
           
        }

        return () => {
            const layers = map.getLayers().getArray();
            for (const lyr of layers) {
                if(lyr.getProperties().alias === "swipe"){
                    //map.removeLayer(lyr);
                   lyr.setVisible(false)
                }
            }
        }
    }, [projectsSwipe, swipeVisibility])
    
    useEffect(() => {
        if(swipeVisibility){

            const listener = function () {
                map.render();
            };
            swipe.addEventListener("input", listener);
            swipe.addEventListener("change", listener);
        } 

    }, [swipeVisibility]);


    useEffect(() => {
        const tileLayer = t.current!;

        if (!props.layername) {

            tileLayer.setSource(new XYZ({
                url: props.url,
                crossOrigin: "anonymous"
            }));

            tileLayer.setVisible(props.visible);
            tileLayer.setZIndex(-1)
        }

        else {
            tileLayer.setSource(new TileWMS({
                url: props.url,
                params: { 'LAYERS': `${props.layername}`, 'TILED': true },
                serverType: 'geoserver',
                crossOrigin: "Anonymous"
            }))
            tileLayer.setZIndex(10)
            tileLayer.setVisible(props.visible)
        }
        map.addLayer(tileLayer);

        return () => {
            map.removeLayer(tileLayer);

        }
    }, []);


    useEffect(() => {
        const tileLayer = t.current!;
        if (props.opacity) {
            tileLayer.setVisible(true)
            tileLayer.setOpacity(props.opacity / 100)
        }
    }, [props.opacity])

    return <TileLayerContext.Provider value={t.current}>{props.children}</TileLayerContext.Provider>;
}