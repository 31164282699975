import './Navbar.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setButtonState, setSidebarButton, setTableButton, setImportButton, setPrintButton, setExportButton, setScreenshotButton, setSwipeButton } from '../../../state/features/buttonStateSlice';
import { setDrawState } from '../../../state/features/drawStateSlice';
import { setSelectedLayer } from '../../../state/features/layerSlice';
import { setPanoramaType, setIsFullScreen } from '../../../state/features/panoramaSlice';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { useAppSelector } from '../../../state/hooks';
import { DrawStateType, MultiDrawTypes } from '../../../util/model/drawState';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { setShowImport } from '../../../state/features/importSlice';
import { setShowPrint } from '../../../state/features/printSlice';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { setGoToSelection } from '../../../state/features/tableSlice';
import { setShowExport } from '../../../state/features/exportSlice';
import { WKT } from 'ol/format';
import Feature from 'ol/Feature';
import axios from 'axios';
import { Language } from '../../language/Language';
import { setToken } from '../../../state/features/customizeSlice';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { GalleryHorizontal } from 'lucide-react';

export const Navbar = ({ showMessage }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const buttonState = useAppSelector(state => state.buttonState.buttonState)
  const tableVisibility = useAppSelector(state => state.buttonState.tableButton)
  const sidebarVisibility = useAppSelector(state => state.buttonState.sidebarButton)
  const screenshotVisibility = useAppSelector(state => state.buttonState.screenshotButton)
  const drawState = useAppSelector(state => state.drawState.value)
  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
  const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
  const user = useAppSelector(state => state.login.user);
  const isLayerVisible = useAppSelector(state => state.layer.isLayerVisible);
  const selectedTableRow = useAppSelector(state => state.table.selectedRow);
  const [searchText, setSearchText] = useState<string>('');
  const [searchInterval, setSearchInterval] = useState<any>(undefined);
  const [quickSearchList, setQuickSearchList] = useState<any[]>([]);
  const [panoType, setPanoType] = useState({ type: 1, label: (t('BUTTON.Floor')) })
  const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
  const token = useAppSelector((state: any) => state.customize.token);
  const services: any = useAppSelector(state => state.layer.services);
  const [GIS_PROJECT, setGIS_PROJECT] = useState<string>("");
  const [isExternalLayer, setIsExternalLayer] = useState<boolean>(false);
  const [drawButtons, setDrawButtons] = useState<({ svg: string; state: DrawStateType; } | { svg: string; state: MultiDrawTypes; })[]>([
    {
      svg: 'icons/point.svg',
      state: DrawStateType.POINT,
    },
    {
      svg: 'icons/line.svg',
      state: DrawStateType.LINESTRING,
    },
    {
      svg: 'icons/polygon.svg',
      state: DrawStateType.POLYGON,
    },
    // {
    //   svg: 'icons/height-draw.svg',
    //   state: DrawStateType.HLINE,
    // },
    // {
    //   svg: 'icons/perpendicular-draw.svg',
    //   state: DrawStateType.ROAD_LINE,
    // },
    // {
    //   svg: 'icons/grid-draw.svg',
    //   state: DrawStateType.GRID,
    // },
    {
      svg: 'icons/clear.svg',
      state: DrawStateType.CLEAR,
    }
  ])

  const [digiButtons, setDigiButtons] = useState([
    {
      svg: 'icons/add-object-icons/nokta_sayisallastirma.svg',
      state: ButtonState.ADD,
    },
    {
      svg: 'icons/add-object-icons/duzenleme.svg',
      state: ButtonState.EDIT,
    },
    {
      svg: 'icons/add-object-icons/vertex_duzenleme.svg',
      state: ButtonState.VERTEX,
    },
    {
      svg: 'icons/add-object-icons/veri_silme.svg',
      state: ButtonState.DELETE,
    },
    {
      svg: 'icons/add-object-icons/veri_kesme.svg',
      state: ButtonState.SPLIT,
    },
    {
      svg: 'icons/add-object-icons/veri_tasima.svg',
      state: ButtonState.MOVE
    },
    {
      svg: 'icons/add-object-icons/veri_paralel.svg',
      state: ButtonState.PARALLEL
    },
  ])
  const PanoTypes = [
    { type: 1, label: (t('BUTTON.Floor')) },
    { type: 2, label: (t('BUTTON.Point Cloud')) },
    { type: 3, label: (t('BUTTON.Multi Display')) }
  ];

  useEffect(() => {
    // dispatch(setSidebarButton(true))
    axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/customize`)
      .then((response: any) => {
        setGIS_PROJECT(response.data.customize.company_name)
      })
  }, []);

  useEffect(() => {
    if (!token && user.id === 0) {
      localStorage.removeItem('token');
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
      dispatch(setToken(undefined));
    }
  }, [user])

  /////// nokta bulutu ile sayıasallşatırmayı açında draw point fonksiyonnu açıyo
  useEffect(() => {
    const drawButtons_ = [
      panoType.type === 1 ? {
        svg: 'icons/point.svg',
        state: DrawStateType.POINT
      } : panoType.type === 2 ?
        {
          svg: 'icons/point.svg',
          state: DrawStateType.PANORAMA_POİNT_PC
        } : {
          svg: 'icons/point.svg',
          state: MultiDrawTypes.POINT_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/line.svg',
        state: DrawStateType.LINESTRING
      } : panoType.type === 2 ?
        {
          svg: 'icons/line.svg',
          state: DrawStateType.PANORAMA_LINE_PC
        } : {
          svg: 'icons/line.svg',
          state: MultiDrawTypes.LINE_MULTI
        },

      panoType.type === 1 ? {
        svg: 'icons/polygon.svg',
        state: DrawStateType.POLYGON,
      } : panoType.type === 2 ?
        {
          svg: 'icons/polygon.svg',
          state: DrawStateType.PANORAMA_POLYGON_PC
        } : {
          svg: 'icons/polygon.svg',
          state: MultiDrawTypes.POLYGON_MULTI
        },

      // panoType.type === 1 ? {
      //   svg: 'icons/height-draw.svg',
      //   state: DrawStateType.HLINE,
      // } : panoType.type === 2 ?
      //   {
      //     svg: 'icons/height-draw.svg',
      //     state: DrawStateType.HLINE
      //   } : {
      //     svg: 'icons/height-draw.svg',
      //     state: MultiDrawTypes.HLINE_MULTI
      //   },

      // panoType.type === 1 ? {
      //   svg: 'icons/perpendicular-draw.svg',
      //   state: DrawStateType.ROAD_LINE
      // } : panoType.type === 2 ?
      //   {
      //     svg: 'icons/triangle-draw.svg',
      //     state: DrawStateType.PANORAMA_TRIANGLE_PC
      //   } : {
      //     svg: 'icons/square-draw.svg',
      //     state: MultiDrawTypes.SQUARE_MULTI
      //   },

      // panoType.type === 1 ? {
      //   svg: 'icons/grid-draw.svg',
      //   state: DrawStateType.GRID
      // } : panoType.type === 2 ?
      //   {
      //     svg: 'icons/square-draw.svg',
      //     state: DrawStateType.PANORAMA_SQUARE_PC
      //   } : {
      //     svg: 'icons/clear-draw.svg',
      //     state: DrawStateType.CLEAR
      //   },

      {
        svg: 'icons/clear.svg',
        state: DrawStateType.CLEAR
      }
    ]
    if (panoType.type === 3) {
      drawButtons_.splice(5, 1)
    }
    setDrawButtons(drawButtons_)
  }, [panoType])

  useEffect(() => {
    if (searchInterval) {
      clearInterval(searchInterval);
    }
    if (searchText.length > 2) {
      const searchTimeout = setTimeout(() => {
        axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/search?search=${searchText}`)
          .then(response => {
            setQuickSearchList(response.data.result);
          })
      }, 250);
      setSearchInterval(searchTimeout);
    }
  }, [searchText])

  const clickOnQuickSearch = async (e: any) => {
    const searchFeature = e.value;
    const wktFormat = new WKT();
    const geometry = wktFormat.readGeometry(searchFeature.st_astext);
    const feature = new Feature({ geometry });
    feature.getGeometry()?.transform('EPSG:4326', 'EPSG:3857')

    dispatch(setSelectedFeature([feature]))
    dispatch(setGoToSelection(true))
    setQuickSearchList([]);
    setSearchText('')
  }

  const changeDigiOperations = (button: any) => {
    if (buttonState === button.state) {
      dispatch(setButtonState(ButtonState.NONE))
    }
    else if (selectedFeature[selectedFeature.length - 1] && selectedFeature[selectedFeature.length - 1].geometry.type !== "MultiLineString" && button.state === 'SPLIT') {
      showMessage(t(`BUTTON.ALERTSPLIT2`))
      dispatch(setButtonState(ButtonState.NONE))
    } else if (!selectedFeature.length && button.state === 'SPLIT') {
      // toast.current?.show({ severity: 'info', detail: (t(`BUTTON.ALERTSPLIT`)), life: 3000 })
      showMessage(t(`BUTTON.ALERTSPLIT`))
      dispatch(setButtonState(ButtonState.NONE))
    }
    else if (!selectedFeature.length && button.state === 'PARALLEL') {
      showMessage(t(`BUTTON.ALERTPARALLEL`))
      dispatch(setButtonState(ButtonState.NONE))
    }
    else if (!selectedFeature.length && button.state === 'MOVE') {
      showMessage(t(`BUTTON.ALERTMOVE`))
      dispatch(setButtonState(ButtonState.NONE))
    }
    else {
      dispatch(setButtonState(button.state))
    }
  }

  const toggleSidebar = () => {
    if (sidebarVisibility) {
      dispatch(setSidebarButton(false))
    }
    else {
      dispatch(setSidebarButton(true))
    }
  }

  const togglePanoType = (e: any) => {
    setPanoType(e.value)
    if (e.value.type === 1) {
      dispatch(setPanoramaType('Floor'))
    }
    if (e.value.type === 2) {
      dispatch(setPanoramaType('Point Cloud'))

    }
    if (e.value.type === 3) {
      dispatch(setPanoramaType('Multi Display'))
    }
  }

  // useEffect(() => {
  //   if(selectedLayer.id &&  panoramaType === 'Point Cloud'){
  //     switch (selectedLayer.geomtype) {
  //       case 'MultiPointZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_POİNT_PC}))
  //         break;
  //       case 'MultiLineStringZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_LINE_PC}))
  //         break;
  //       case 'MultiPolygonZ':
  //         dispatch(setDrawState({drawStateType: DrawStateType.PANORAMA_POLYGON_PC}))
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [selectedLayer, panoType, buttonState])


  useEffect(() => {
    const geomType: any = selectedLayer.geomtype;

    if (geomType !== undefined) {
      let updatedSvg: any
      if (geomType === "MultiPoint" || geomType === "MultiPointZ") {
        updatedSvg = "icons/add-object-icons/nokta_sayisallastirma.svg";
      } else if (geomType === "MultiLineString" || geomType === "MultiLineStringZ") {
        updatedSvg = "icons/add-object-icons/cizgi_sayisallastirma.svg";
      } else if (geomType === "MultiPolygon" || geomType === "MultiPolygonZ") {
        updatedSvg = "icons/add-object-icons/alan_sayisallastirma.svg";
      }
      setDigiButtons(digiButtons.map((button: any) => {
        if (button.state === ButtonState.ADD) {
          return { ...button, svg: updatedSvg }
        }
        return button
      }))
    }

  }, [selectedLayer]);

  const changeDrawState = (buttonState: any) => {
    if (buttonState === drawState.drawStateType) {
      dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
    }
    else {
      // if(panoType.type === 2){
      //   toast.current?.show({ severity: 'info', detail: ('Veri bulunamadı'), life: 355000 });
      // }
      dispatch(setDrawState({ drawStateType: buttonState }))
    }
  }

  const toggleInfo = () => {
    dispatch(setSelectedFeature([]))
    if (buttonState === ButtonState.INFO) {
      dispatch(setButtonState(ButtonState.NONE))
      /** clear selection ! */
      dispatch(setSelectedFeature([]))
    }
    else {
      dispatch(setButtonState(ButtonState.INFO))
    }
  }

  const toggleSelection = () => {
    dispatch(setSelectedFeature([]))
    if (buttonState === ButtonState.SELECTION) {
      dispatch(setButtonState(ButtonState.NONE))
      /** clear selection ! */
      dispatch(setSelectedFeature([]))
    }
    else {
      dispatch(setButtonState(ButtonState.SELECTION))
    }
  }

  const toggleTable = () => {
    if (tableVisibility) {
      dispatch(setTableButton(false))
      dispatch(setSelectedLayer({}))
    }
    else {
      dispatch(setTableButton(true))
    }
  }

  const toggleHelp = () => {
    if (buttonState === ButtonState.HELP) {
      dispatch(setButtonState(ButtonState.NONE))
    }
    else {
      dispatch(setButtonState(ButtonState.HELP))
    }
  }  
  const toggleSwipe = () => {
    if (buttonState === ButtonState.SWIPE) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setSwipeButton(false))
    }
    else {
      dispatch(setButtonState(ButtonState.SWIPE))
      dispatch(setSwipeButton(true))
    }
  }
  
  const toggleImport = () => {
    if (buttonState === ButtonState.IMPORT) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setImportButton(false))
      dispatch(setShowImport(false))
    }
    else {
      dispatch(setButtonState(ButtonState.IMPORT))
      dispatch(setImportButton(true))
      dispatch(setShowImport(true))
    }
  }
  const toggleExport = () => {
    if (buttonState === ButtonState.EXPORT) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setExportButton(false))
      dispatch(setShowExport(false))
    }
    else {
      dispatch(setButtonState(ButtonState.EXPORT))
      dispatch(setExportButton(true))
      dispatch(setShowExport(true))
    }
  }
  const togglePrint = () => {
    if (buttonState === ButtonState.PRINT) {
      dispatch(setButtonState(ButtonState.NONE))
      dispatch(setPrintButton(false))
      dispatch(setShowPrint(false))
    }
    else {
      dispatch(setButtonState(ButtonState.PRINT))
      dispatch(setPrintButton(true))
      dispatch(setShowPrint(true))
    }
  }

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      dispatch(setButtonState(ButtonState.FULLSCREEN))
    }
    else {
      dispatch(setButtonState(ButtonState.NONE))
    }
  }
  const toggleRoadProfile = () => {
    if (buttonState === ButtonState.ROAD_PROFILE) {
      dispatch(setButtonState(ButtonState.NONE))
    }
    else {
      dispatch(setButtonState(ButtonState.ROAD_PROFILE))
    }
  }

  const logOutConfirm = () => {
    // setLogOutConfirmVisibility(false)

    navigate("login")
    localStorage.removeItem('token')
  }
  const onLogoutClick = () => {
    confirmDialog({
      message: t('CONFIRMATION.Logout'),
      header: t('HEADER.Confirmation'),
      icon: 'pi pi-exclamation-triangle',
      className: 'attach-confirm-dialog logout-confirm',
      accept: async () => {
        try {
          logOutConfirm()
        } catch (error) {
          console.error(error);
        }
      },
      reject: () => { },
      acceptLabel: `${t('BUTTON.Yes')}`,
      rejectLabel: `${t('BUTTON.Cancel')}`,
    });
  }

  return (
    <>
      <div className="Navbar non-printable">
        <div className='left-content'>
          <div className='sidebar-button'>
            <Button
              icon='selected-icons/layers.svg'
              className={sidebarVisibility ? 'active-button' : ''}
              onClick={toggleSidebar}
              tooltipOptions={{ position: 'bottom' }}
              tooltip='Layers'>
              <img src='icons/layers.svg' alt="" />
            </Button>

            <div className='text'>
              {GIS_PROJECT}
            </div>
          </div>
          <div>
            {/* <Dropdown
              className='pano-type-dropdown'
              value={panoType}
              options={PanoTypes}
              onChange={(e) => togglePanoType(e)}
              optionLabel="label"
            /> */}
            {
              digiButtons.map((button: any) =>
                <Button key={button.svg}
                  className={buttonState === button.state ? 'active-button' : ''}
                  onClick={() => changeDigiOperations(button)}
                  tooltipOptions={{ position: 'bottom' }}
                  tooltip={t(`BUTTON.${button.state}`)}
                  disabled={!selectedLayer.name}>
                  <img src={button.svg} alt="" />
                </Button>)
            }
            <Button
              className={buttonState === ButtonState.HOLE ? 'active-button' : ''}
              onClick={() => dispatch(setButtonState(ButtonState.HOLE))}
              disabled={(selectedFeature.length === 0 ||
                (selectedFeature[0].id.split('.')[0] !== selectedLayer.name ||
                  !selectedFeature[0].geometry.type.includes('Polygon') ||
                  !selectedLayer.geomtype.includes('Polygon') ||
                  !isLayerVisible ||
                  !selectedLayer.name ||
                  selectedTableRow
                ))}
              tooltipOptions={{ position: 'bottom' }}
              tooltip={t(`BUTTON.HOLE`)}>
              <img src='icons/add-object-icons/delikli_poligon.svg' alt="" />
            </Button>
          </div>
          <div className='vertical-line'></div>
          <div>
            <Button
              className={buttonState === ButtonState.INFO ? 'active-button' : ''}
              onClick={toggleInfo}
              disabled={!isLayerVisible}
              tooltipOptions={{ position: 'bottom' }}
              tooltip={t(`BUTTON.INFO`)}>
              <img src='icons/info.svg' alt="" />
            </Button>
            <Button
              className={buttonState === ButtonState.SELECTION ? 'active-button' : ''}
              disabled={!isLayerVisible}
              onClick={toggleSelection}
              tooltipOptions={{ position: 'bottom' }}
              tooltip={t(`BUTTON.Selection`)}>
              <img src='icons/selection.svg' alt="" />
            </Button>
            <Button
              className={tableVisibility ? 'active-button' : ''}
              disabled={(!tableVisibility && !selectedLayer.name ? true : false) || (!tableVisibility && selectedLayer.name ? (services.find((service: any) => service.id === selectedLayer.service_id)?.type === 'EXTERNAL_LAYER' || services.find((service: any) => service.id === selectedLayer.service_id)?.type === 'RASTER') : false)}
              onClick={toggleTable}
              tooltipOptions={{ position: 'bottom' }}
              tooltip={t(`BUTTON.TABLE`)}>
              <img src='icons/table.svg' alt="" />
            </Button>

          </div>
          <div className='vertical-line'></div>
          <div>
            {
              drawButtons.map((button: any) =>
                <Button key={button.svg}
                  className={drawState.drawStateType === button.state && button.state !== DrawStateType.CLEAR ? 'active-button' : ''}
                  onClick={() => changeDrawState(button.state)}
                  tooltipOptions={{ position: 'bottom' }}
                  tooltip={t(`BUTTON.${button.state}`)}>
                  <img src={button.svg} alt="" />
                </Button>
              )
            }
          </div>
          <div className='vertical-line'></div>
          <Button
            className={buttonState === ButtonState.IMPORT ? 'active-button' : ''}
            onClick={toggleImport}
            tooltip={t(`BUTTON.Import`)}
            tooltipOptions={{ position: 'bottom' }}
            style={{ width: '30px' }}
          >
            <img src='icons/import-button.svg' alt="" />
          </Button>
          <Button
            className={buttonState === ButtonState.EXPORT ? 'active-button' : ''}
            onClick={toggleExport}
            tooltip={t(`BUTTON.Export`)}
            tooltipOptions={{ position: 'bottom' }}
            style={{ width: '30px' }}
          >
            <img src='icons/export-button.svg' alt="" />
          </Button>

          {/* <div className='vertical-line'></div> */}
          {/* <Button
            className={buttonState === ButtonState.ROAD_PROFILE ? 'active-button' : ''}
            onClick={toggleRoadProfile}
            tooltip='Yol Profili'
            tooltipOptions={{ position: 'bottom' }}
            style={{ width: '30px' }}
          >
            <img src='icons/road-profile.svg' alt="" />
          </Button> */}
          <div className='vertical-line'></div>
          <Button
            className={buttonState === ButtonState.PRINT ? 'active-button' : ''}
            onClick={togglePrint}
            tooltip={t(`BUTTON.Print`)}
            tooltipOptions={{ position: 'bottom' }}
            style={{ width: '30px' }}
          >
            <img src='icons/print.svg' alt="" />
          </Button>
          <Button
            className={buttonState === ButtonState.HELP ? 'active-button' : ''}
            onClick={toggleHelp}
            tooltip={t(`BUTTON.Help`)}
            tooltipOptions={{ position: 'bottom' }}
            icon='pi pi-question'
            style={{ width: '30px' }}
          />
          <div className='vertical-line'></div>
          <Button
            className={buttonState === ButtonState.SWIPE ? 'active-button' : ''}
            onClick={toggleSwipe}
            tooltip={t(`BUTTON.SWIPE`)}
            tooltipOptions={{ position: 'bottom' }}
            icon={<GalleryHorizontal color="#fff" size={18} />}
            style={{ width: '30px' }}
          />
          {/* <Button
            className={screenshotVisibility ? 'active-button' : ''}
            onClick={() => { dispatch(setScreenshotButton(true)) }}
            tooltipOptions={{ position: 'bottom' }}
            tooltip={t(`BUTTON.Screenshot`)}>
            <img src='icons/screenshot.svg' alt="" />
          </Button> */}
        </div>
        <div className='right-content'>
          <span className="p-input-icon-right">
            <i className={`${searchText ? 'pi pi-times' : ''}`} onClick={() => { setSearchText(''); dispatch(setSelectedFeature([])) }} />
            <InputText className='search-input' value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={t('BUTTON.Search')} />
            {
              quickSearchList.length > 0 &&
              <ListBox options={quickSearchList} onChange={clickOnQuickSearch} optionLabel={quickSearchList[0].search_field}
                style={{ position: 'absolute', maxHeight: '500px', overflow: 'auto' }}>
              </ListBox>
            }
          </span>
          {/* <Language /> */}
          {/* <Button
            className={buttonState === ButtonState.FULLSCREEN ? 'active-button' : ''}
            onClick={() => { toggleFullScreen(); dispatch(setIsFullScreen(!isFullScreen)) }}
            tooltipOptions={{ position: 'bottom' }}
            tooltip={t(`BUTTON.Full Screen`)}>
            <img src='icons/full-screen.svg' alt="" style={{ marginLeft: '4px' }} />
          </Button> */}
          <Button
            className='logout-button'
            onClick={onLogoutClick}
            tooltipOptions={{ position: 'bottom' }}
            tooltip={t(`BUTTON.LOGOUT`)}>
            <span>{user.firstname + ' ' + user.lastname}</span> <img src='icons/exit.svg' alt="" />
          </Button>
          <ConfirmDialog />
        </div>
      </div>
      {/* <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '444444 !important' }} /> */}
    </>
  )
}