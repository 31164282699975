import { useEffect, useState } from 'react';
import { useAppSelector } from '../../state/hooks';
import { RootState } from '../../state/store';
import { Project } from '../../util/model/project';
import './Legend.scss';

interface IProps {
    index: number
}

export const Legend = (props: IProps) => {
    const index = props.index;
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
    const services: any = useAppSelector(state => state.layer.services);
    const [wMSUrls, setWMSUrls] = useState<any>([]);

    useEffect(() => {
        const layerURLs_: any = [];

        projects.forEach((project: any) => {
            project.layer_groups.forEach((layerGroup: any) => {
                layerGroup.layers.forEach((layer: any) => {
                    if (layer.visible) {
                        const offset: string = services.find((service: any) => service.id === layer.service_id)?.url.replace("rest/v1/", "");
                        const wmsUrl: string = `${offset}/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=${layer.name}&format=image/png&legend_options=fontName:Segoe%20UI;fontAntiAliasing:true;fontColor:0xFFFFFF;fontSize:6;bgColor:0x004290;dpi:300`;
                        layerURLs_.push(wmsUrl);
                    }
                });
            });
        });

        setWMSUrls(layerURLs_);
    }, []);

    return (
        <div className='only-printable Legend'>
            {wMSUrls.map((wmsUrl: any) => (
                <div key={wmsUrl} className='lejant-info'>
                    <img id="legend" crossOrigin='anonymous' src={wmsUrl} />
                </div>
            ))}
        </div>
    );
};