import "./Print.scss";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Project } from "../../util/model/project";
import { RootState } from "../../state/store";
import {
  setButtonState,
  setPrintButton,
} from "../../state/features/buttonStateSlice";
import { ButtonState } from "../shared/button-state/ButtonState";
import {
  setPrintPreviewMode,
  setShowPrint,
} from "../../state/features/printSlice";
import { useTranslation } from "react-i18next";
import CheckBox from "../shared/custom/CheckBox";
import Moment from "moment";
import html2canvas from 'html2canvas';

export const Print = ({ selectedPrint, printPreview }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const map = (window as any).olMap;
  const [hasNote, setHasNote] = useState<boolean>(false);
  const [scale, setscale] = useState<any>("1 : 3,395,295");
  const [error, setError] = useState<number>(0);
  const projects: Project[] = useAppSelector(
    (state: RootState) => state.layer.projects
  );
  const services: any = useAppSelector((state) => state.layer.services);
  const printPreviewMode: boolean = useAppSelector(
    (state) => state.print.printPreviewMode
  );
  const [noteValue, setNoteValue] = useState<string>("");
  const panoramaInfo = useAppSelector((state) => state.panorama.info);
  const [dateValue, setDateValue] = useState<boolean>(false);
  const [hasCompass, setHasCompass] = useState<boolean>(false);
  const [hasPanoramaLocation, setHasPanoramaLocation] =
    useState<boolean>(false);

  const closeWindow = async () => {
    document.getElementsByClassName("home")[0].classList.remove("both");
    document
      .getElementsByClassName("home")[0]
      .classList.remove("only-panorama");
    document.getElementsByClassName("home")[0].classList.remove("only-map");
    document.getElementsByClassName("home")[0].classList.remove("print-start");
    dispatch(setPrintPreviewMode(false));
    dispatch(setShowPrint(false));
    dispatch(setPrintButton(false));
    dispatch(setButtonState(ButtonState.NONE));
  };
  const viewChange = (value: any) => {
    console.log("CHECK change : ", value);
    document.getElementsByClassName("home")[0].classList.remove("both");
    document
      .getElementsByClassName("home")[0]
      .classList.remove("only-panorama");
    document.getElementsByClassName("home")[0].classList.remove("only-map");
    document.getElementsByClassName("home")[0].classList.add(value);
    selectedPrint(value);
  };
  const compassChange = (value: any) => {
    setHasCompass(value);
  };
  const [imgURL, setImgURL] = useState<any>(null);

  const canvasRef:any = useRef(null);

  const prefixFiller = (
    value: string,
    prefixValue: string,
    expectedStringLen: number
  ): string => {
    if (typeof value !== "string") {
      throw new Error("value format is not expected");
    }
    let stringValue;
    if (prefixValue.length === 0) {
      throw new Error("length of prefixValue should not equals to 0");
    }
    if (prefixValue.length > 1) {
      throw new Error("length of prefixValue should be 1");
    }
    stringValue = value;

    const len = stringValue.length;
    if (len > expectedStringLen) {
      return stringValue;
    } else {
      let result = "";
      let i = 0;
      const count = expectedStringLen - value.length;
      while (i < count) {
        i++;
        result += prefixValue;
      }
      return result + stringValue;
    }
  };

  const dateChange = (value: any) => {
    setDateValue(value);
    // if (dateValue !== '') {
    //   const date = new Date();
    //   const day = prefixFiller(date.getDate().toString(), '0', 2);
    //   const month = prefixFiller((date.getMonth() + 1).toString(), '0', 2);
    //   const year = prefixFiller((date.getFullYear()).toString(), '0', 4);
    //   const text = `${day}/${month}/${year}`;
    //   setDateValue(text);
    // } else {
    //   setDateValue('')
    // }
  };
  const panoramaInfoChange = (value: any) => {
    setHasPanoramaLocation(value);
    // setHasNote(!hasNote);
  };
  const noteChange = (value: any) => {
    setHasNote(/*!hasNote*/ value);
  };

  const textValueChange = (value: any) => {
    setNoteValue(value);
  };

  useEffect(() => {
    if (printPreviewMode) {
      /*document.body.classList.add('print-start');*/
      document.getElementsByClassName("home")[0].classList.add("print-start");
      capturePrintArea()
      // dispatch(setPrintPreviewMode(true))
    } else {
      /*document.body.classList.remove('print-start');*/
      document
        .getElementsByClassName("home")[0]
        .classList.remove("print-start");
        setImgURL(null)
      // dispatch(setPrintPreviewMode(false))
    }
  }, [printPreviewMode]);

  const previewChange = () => {
    if (!printPreviewMode) {
      // document.body.classList.add('print-start');
      printPreview(true)
      dispatch(setPrintPreviewMode(true));

    } else {
      // document.body.classList.remove('print-start');
      dispatch(setPrintPreviewMode(false));
      printPreview(false)
    }
    //this.noteValue = value;
  };

  const doneAfter = () => { 
    previewChange();
    setImgURL(null)
    
    setTimeout(() => {
      dispatch(setPrintButton(false));
      dispatch(setButtonState(ButtonState.NONE));
    }, 100);
  } 
  (document as any).body.onafterprint = doneAfter;

  const capturePrintArea = () => {
    const printArea:any = document.getElementsByClassName('home'/* 'Map' */)[0] //document.body;
    console.log("printArea :",printArea);
    
    const pDialog:any = document.getElementsByClassName('print-dialog')[0] // Select the p-dialog element



    // const combinedContainer :any  = document.createElement('div');
    
    // Get the elements
    const element2 :any = document.getElementById('legend');
    // const element1 :any = document.getElementById('home');
    
    // Append both elements into the new container
  /*   combinedContainer.appendChild(element1.cloneNode(true));
    combinedContainer.appendChild(element2.cloneNode(true)); */
    
    // Append the combined container to the body or somewhere else temporarily
    // document.body.appendChild(combinedContainer);



    html2canvas(printArea,/* { width: 800, height: 600 } */).then((canvas:any) => {
        if (pDialog) {
            pDialog.style.display = 'block';
        }
        document.body.appendChild(canvas); // For testing, you might append it to the DOM
      
        // const img:any = document.getElementById('legend');
        // console.log("img :",img);
        
        const ctx = canvas.getContext('2d');
        // console.log("ctx :",ctx);
        

        // Draw something on the canvas
        // ctx.fillRect(0, 0, 200, 200);


          const imgWidth = element2.width;
          const imgHeight = element2.height;
      
          // Calculate the bottom-right position
          const x = canvas.width - imgWidth;
          const y = canvas.height - imgHeight -40;
      
          // Draw the image at the calculated position
      //    ctx.drawImage(element2, x, y);

        // ctx.drawImage(element2, 200, 200);

        // Convert the canvas to a data URL (representing an image)
        // const imageData = canvas.toDataURL('image/png'); // You can specify the image format, e.g. 'image/jpeg'

        // Use the imageData to create an image element
        // const img:any = new Image();
        // img.src = imageData;
        // console.log("img :",img);
        // Append the image element to the DOM or use it further

        // Draw something on the canvas first (e.g., a rectangle)
       /*  ctx.fillStyle = "lightblue";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
   */
        // Once the image is loaded, draw it on the canvas
   /*      img.onload = function() {
          // Draw the image on the canvas at coordinates (100, 100)
          ctx.globalCompositeOperation = 'multiply';
          ctx.drawImage(img, 100, 100);
          
          // Optionally, you can use globalCompositeOperation to blend or manipulate layers
          // Example: ctx.globalCompositeOperation = 'multiply';
          // ctx.drawImage(img, 100, 100);
        }
       */

        // Append or display the canvas as needed
        // Alternatively, convert canvas to data URL for an image preview
        // document.body.removeChild(combinedContainer);
        const imgURL = canvas.toDataURL("image/png");
        setImgURL(imgURL)
        // console.log(imgURL); // You can log the image URL or display it in an <img> tag
    }).catch(error => {
        console.error("Error capturing print area:", error);
    });
  }

  const startPrint = () => {
    if (printPreviewMode) {
      // const element = document.getElementsByClassName('print-dialog')[0] as HTMLElement;
      const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
      // element.style.display = "none"
      element2.style.display = "none";
      (window as any).print();
    } else {
      previewChange();
        setTimeout(() => {
        const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
        element2.style.display = "none";
        (window as any).print();
      }, 500);
    }
  };
  return (
    <div className="Print">
      <Toast ref={toast}></Toast>
      <Dialog
        // modal={false}
        className="print-dialog"
        header={t(`BUTTON.Print`)}
        visible={true}
        onHide={closeWindow}
        resizable={false}
      >
        <div className="pair">
          <div className="item">
              <fieldset style={{display:!printPreviewMode ? "block": "none"}} className="fieldset2">
                <legend className="item-header">{t(`BUTTON.InfoSelect`)}</legend>

                <CheckBox
                  label={t(`BUTTON.NORTH`)}
                  changeChecked={compassChange}
                />
                <CheckBox label={t(`BUTTON.DATE`)} changeChecked={dateChange} />
                {/* <CheckBox
                  label={t(`BUTTON.PANORAMALOCATION`)}
                  changeChecked={panoramaInfoChange}
                /> */}
                <CheckBox label={t(`BUTTON.NOTE`)} changeChecked={noteChange} />

                {hasNote && (
                  <textarea
                  style={{marginTop:"12px", height:"50px"}}
                    onChange={(e) => textValueChange(e.target.value)}
                  ></textarea>
                )}
              </fieldset>

            {
              printPreviewMode &&
                <img
                style={{zIndex:"99999"}}
                  width={550}
                  height={300}
                  src={imgURL}
                  className="print-canvas"
                />
            }
          </div>

          <br></br>

          <div className="footer">
            <CheckBox
              changeChecked={previewChange}
              label={t(`BUTTON.Preview`)}
            />

            <Button
              onClick={startPrint /*onClickPrint*/}
              label={t(`BUTTON.Print`)}
            ></Button>
          </div>

          <br></br>
          {/* <span
          style={{ display:error===2?'block':'none', color:'red' }}
          >
           Aynı isimde katman var. Lütfen farklı isimde dosya seçiniz
          </span>
          */}
        </div>
      </Dialog>

      {printPreviewMode && hasNote && noteValue && (
        <div className="kkn-info right-bottom only-printable">
          {hasNote && <div style={{ whiteSpace: "pre" }}>{noteValue}</div>}
        </div>
      )}
      {printPreviewMode && hasCompass && (
        <div className="right-top only-printable">
          <img src="icons/north-arrow.png"></img>
        </div>
      )}
      {printPreviewMode && (
        <div className="kkn-info only-printable">
          {dateValue && (
            <div>
              Date:{" "}
              {Moment(new Date(panoramaInfo.shootingDate)).format(
                "DD-MM-YYYY hh:mm"
              )}
            </div>
          )}
          {(dateValue || hasPanoramaLocation) && (
            <div style={{ paddingLeft: "4px", paddingRight: "4px" }}> | </div>
          )}
          {hasPanoramaLocation && (
            <div>
              Panorama Konum: {panoramaInfo.label /*panoramaLocationValue*/}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
